// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-adult-learning-jsx": () => import("../src/pages/adult-learning.jsx" /* webpackChunkName: "component---src-pages-adult-learning-jsx" */),
  "component---src-pages-careers-jsx": () => import("../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-core-values-jsx": () => import("../src/pages/core-values.jsx" /* webpackChunkName: "component---src-pages-core-values-jsx" */),
  "component---src-pages-early-education-jsx": () => import("../src/pages/early-education.jsx" /* webpackChunkName: "component---src-pages-early-education-jsx" */),
  "component---src-pages-feedback-jsx": () => import("../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-franchise-jsx": () => import("../src/pages/franchise.jsx" /* webpackChunkName: "component---src-pages-franchise-jsx" */),
  "component---src-pages-k-12-school-locations-jsx": () => import("../src/pages/k12-school-locations.jsx" /* webpackChunkName: "component---src-pages-k-12-school-locations-jsx" */),
  "component---src-pages-k-12-schools-timeline-jsx": () => import("../src/pages/k12-schools-timeline.jsx" /* webpackChunkName: "component---src-pages-k-12-schools-timeline-jsx" */),
  "component---src-pages-k-12-schools-jsx": () => import("../src/pages/k12-schools.jsx" /* webpackChunkName: "component---src-pages-k-12-schools-jsx" */),
  "component---src-pages-language-centers-jsx": () => import("../src/pages/language-centers.jsx" /* webpackChunkName: "component---src-pages-language-centers-jsx" */),
  "component---src-pages-lwes-curriculum-jsx": () => import("../src/pages/lwes-curriculum.jsx" /* webpackChunkName: "component---src-pages-lwes-curriculum-jsx" */),
  "component---src-pages-organisation-jsx": () => import("../src/pages/organisation.jsx" /* webpackChunkName: "component---src-pages-organisation-jsx" */),
  "component---src-pages-partnerships-jsx": () => import("../src/pages/partnerships.jsx" /* webpackChunkName: "component---src-pages-partnerships-jsx" */),
  "component---src-pages-philosophy-jsx": () => import("../src/pages/philosophy.jsx" /* webpackChunkName: "component---src-pages-philosophy-jsx" */),
  "component---src-pages-schools-for-good-jsx": () => import("../src/pages/schools-for-good.jsx" /* webpackChunkName: "component---src-pages-schools-for-good-jsx" */),
  "component---src-pages-teachers-education-jsx": () => import("../src/pages/teachers-education.jsx" /* webpackChunkName: "component---src-pages-teachers-education-jsx" */),
  "component---src-pages-team-jsx": () => import("../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-why-lwes-school-jsx": () => import("../src/pages/why-lwes-school.jsx" /* webpackChunkName: "component---src-pages-why-lwes-school-jsx" */)
}

